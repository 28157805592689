#flash{ 

  width:100%;
  padding:15px;
  background-color:rgba(0,0,0,0.8);
  color:#fff;
  text-align: center;
  font-family: Raleway;
  font-size:14px;
  position: fixed;
  top:-100px;
  transition: top 0.5s;
  z-index:100;

  #flash-message{
    max-width:300px;    
    margin:0px auto;
  }

  &.open{
    top:0px;
  }
}