#not-supported{
  width:300px;
  margin:50px auto;
  min-height:400px;

  background-image:url("front/Logo-White.svg");
  background-repeat:no-repeat;
  background-position: center top; 
  background-size: 100px;

  padding:150px 20px;


  text-align:center;
  font-family:'Raleway';
  font-size:16px;
  line-height:24px;

  a{
    color:inherit;
    text-decoration:underline;
  }

}