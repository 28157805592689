p{
  font-family:'Raleway'; 
}

h1,h2,h3,h4,h5{
  font-family:'Cinzel';
}

html, body{
  height:100%;  
  background-color:#0A1321;
  color:#fff;
  padding:0px;
  margin:0px;
}

.body-text{
  font-size:14px;
  line-height:20px;
  font-family:'Raleway';
  color:#fff;
}

#page{
  position:relative;


  .container{
    width:calc(100% - 60px);
    margin-left:30px;
    margin-right:30px;
  }

  .page-header{
    position:fixed;
    width:100%;
    top:0px;
    height:60px;
    color:#fff;
    z-index:2;
    background-color:#0B151D;

    .container{
      height:100%;    
      position:relative;
      z-index:2;
      display:flex;
      width:100%;
      box-sizing:border-box;
      padding:0px 20px;
      margin-left:0px;
      margin-right:0px;
      flex-direction:row;
      justify-content:flex-start;
      align-items:center;
      background-color:#0B151D;

      .navigation{
        display:none;
      }
      .button{display:none;}
    }

    .back-link{
      display:flex;
      align-items:center; 
      background-image:url("images/roles/back-arrow.svg");
      height:25px;
      background-size:25px 25px;      
      padding-left:35px; 
      font-family: 'Cinzel Decorative', cursive;
      font-size:14px; 
      background-repeat:no-repeat;
      margin-left:0px;
      color:#fff;
    }

    .header-logo{
      background-image:url("images/front/Logo-PhoneMenu.svg");
      background-size:contain;
      background-repeat:no-repeat; 
      background-position:center center;
      margin:5px auto 0px auto;
      width:calc(100% - 40px);
      height:50px;
      flex:none;
    }

    .hamburger{    
      width:40px;
      height:40px;
      background-color:transparent;
      margin-left:auto;
      margin-top:20px;
      padding:0px;

/*      .hamburger-box{
        height:40px;
      }
*/
      .hamburger-inner{
        background-color:#fff;
        width:30px;
        height:2px;
        &:after,
        &:before{
          width:30px;
          height:2px;
          background-color:#fff;
        }
      }
    }

    .mobile-menu{
      position:absolute;
      width:100%;
      left:0px;
      top:-360px;
      display:flex;
      flex-direction:column;
      background-color:#0B151D;
      transition: top 0.5s;

      &.is-active{
        top:60px;
      }

      .navigation{
        display:block;
        margin:20px;
      }

      .button{
        display:block;
        margin-left:initial;
        margin:0px 20px 20px 20px;
      }
    }

    .navigation{
      text-transform:uppercase;
      font-size:22px;
      color:#fff;
      margin-right:27px;
      font-family:'Cinzel';
    }

    .button{
      display:inline-block;
      margin-left:auto;
      font-size:20px;
      border:#707070 1px solid;
      background-color:#fff;
      border-radius:7px;
      text-transform:uppercase;
      color:#000;
      text-align:center;
      padding:12px 22px;
      font-family:'Cinzel';
    }
  }

  #hero{
    margin-top:60px;
    min-height:500px;
    height:calc(100vh - 60px);

    background-image:url('images/mobile/PosterImage.jpg');
    background-repeat:no-repeat;
    background-position:center right;

    .container{
      height:100%;
      display:flex;
      flex-direction:column;
    }

    .arrow-container{
      display:none;
    }

    p{
      font-size:20px;
      margin-top:auto;
      margin-bottom:50px;
      line-height: 22px;
    }
  }

  #teaser{
    background-image:url('images/mobile/Teaser-Bg.jpg');
    background-size:cover;
    background-position:center center;

    .container{
      padding:90px 0px;
    }

  }

  #welcome{
    background-color:#132432;

    .container{
      display:flex;
      flex-direction:column;
      padding:27px 0px 40px 0px;
    }

    .column{
      width:100%;

      h3{
        font-size:30px;
        text-transform:uppercase;
        margin-bottom:20px;
      }

      p{
        font-size:14px;
        line-height:20px;
      }

      &:last-child{
        margin-top:34px;
      }
    }
  }

  blockquote{
    padding:33px 0px 57px 0px;

    .container{
      display:flex;
      flex-direction:column;
      justify-content:flex-start;
      align-items:center;
    }

    img{
      width:206px;
      height:238px;
    }

    p{
      font-size:18px;
      line-height:24px;
      margin-top:30px;
      font-style:italic;
      color:#C6C6C6;
    }

    &.image-right{        
      p{
        margin-bottom:30px;
      }
    }
  }

  #vision{
    background-image: url('images/mobile/TopVision-Bg.jpg');
    background-size:cover;

    .container{
      padding:52px 0px 80px 0px;
    }

    h3{
      font-size:24px;
      margin-bottom:10px;
    }
  }

  #vision2{
    background-image: url('images/mobile/BottomVision-Bg.jpg');
    background-size:cover;

    .container{
      padding:40px 0px;
    }
  }

  #themes{
    .container{
      padding:40px 0px 60px 0px;
      min-height:260px;
    }

    h4{
      font-size:18px;
      text-align:center;
    }

    .theme-matrix{
      box-sizing:border-box;
      height:100%;
      background-position:center center;
      background-image: url('images/front/ThemeMatrix.svg');
      background-repeat:no-repeat;
      height:155px;
      margin-top:50px;
      .label{display:none;}
    }
  }
  
  #viewpoint{
    background-color:#132432;
    .container{
      padding:40px 0px;
    }
  }

  #rainbow{
    position:relative;
    background-color:#0A1321; 
    background-image: url('images/front/rainbow-pillar.svg'); 
    background-position:center top;
    background-size:contain;
    background-repeat:no-repeat;
    font-style:italic;
    color:#C6C6C6; 
    font-family:'Raleway';
    font-size:20px;
    line-height:26px;

    &:after{
      content: ' ';
      position:absolute;
      bottom:0px;
      width:100%;
      height:100px;
      background-image:url('images/front/rainbow-bottom.svg');
      background-repeat:no-repeat;
      background-position:center bottom;
      background-size:cover;
      background-color:#0A1321; 
    }

    .container{
      background-image: url('images/front/rainbow-header.png');
      background-repeat:no-repeat;
      background-position:center 104px;
      background-size:208px 240px;
      padding:392px 0px 200px 0px;
    }
  }

  .spacer{
    height:49px;
  }

  #story{
    background-image: url('images/mobile/Story-Bg.jpg');
    background-size:cover;

    .container{
      padding:40px 0px 60px 0px;
    }

    h3{
      font-size:24px;
      margin-bottom:20px;
    }

    strong{
      font-weight: bold;
    }

    a{
      color:inherit;
      text-decoration: underline;
    }
  }

  #meta{
    background-image: url('images/mobile/Meta-bg.jpg');
    background-position: center center;
    background-size:cover;

    .container{
      padding:40px 0px 60px 0px;
    }

    h3{
      font-family: 'Cinzel Decorative', cursive;
      font-size:24px;
      margin-bottom:20px;
    }
  }

  #paper{
    background-color:#E2E1DA;

    .container{
      background-image: url('images/front/NyGryning-Logo.svg');
      background-size:268px 100px;
      background-position:left 20px;
      background-repeat:no-repeat;
      padding:170px 0px 60px 0px;    
    }

    h5{
      color:#242424;
      font-family:'Georgia';
      margin-bottom:10px;

      span{
        color:#AAA;
      }
    }

    h2{
      color:#242424;
      font-family:'Georgia';
      margin-bottom:20px;
      font-size:40px;
    }

    p{
      font-family:'Georgia';
      color:#242424;
      font-size:14px;
      line-height:20px;
    }

    .byline{
      margin-top:30px;
      display:flex;
      background-image: url('images/front/byline.png');
      background-position:center left; 
      background-repeat:no-repeat;
      height:120px;
      flex-direction:column; 
      justify-content:center;
      background-size:100px 100px; 
      padding:0px 0px 0px 120px;

      h4{
        font-family:'Georgia';
        font-weight:bold;
        color:#242424; 
        font-size:22px;

        span{
          font-size:18px;
          font-weight:normal;          
        }
      }
    }
  }

  #tattoo{
    background-image: url('images/mobile/Tatto-Spacer.jpg');
    background-size:cover;
    background-position:center top;
    height:200px;
  }

  .info-section{
    .container{
      padding:40px 0px 60px 0px;
    }

    h3{
      font-size:24px;
      margin-bottom:20px;
    }

    strong{
      font-weight: bold;
    }
  }


  #hands{
    background-image: url('images/mobile/Hands-Spacer.jpg');
    background-size:cover;
    background-position:center center; 
    height:200px;
  }

  #hand{
    background-image:url("images/mobile/PhasesCover.jpg");
    background-repeat:no-repeat;
    background-size:cover;
    background-position:center center;
    position:relative;
    height:200px;
    display:flex;
    justify-content:center;
    align-items:center;

    a{
      border:1px solid #707070; 
      background-color:#fff;
      color:#0A1321;
      font-family:'Cinzel';
      font-size:20px;
      padding:15px 24px;
      border-radius:20px;
     }
  }

  #roles{
    background-color:#132432;
  }


  #role-types{
    background-color:#0A1321;
    background-size:318px 368px;
    background-position:center center;
    background-repeat:no-repeat;
    padding:60px 0px;

    h3{
      font-size:24px;
      margin-bottom:20px;
    }

    ul{
      list-style-type: disc;
      list-style-position: outside;

      li{
        margin-bottom: 20px;
      }
    }

    .column{
      margin:0px 0px 50px 0px;

      &:last-child{
        margin-bottom:0px;
      }
    }

    .container{
      display:flex;
      flex-direction:column;
      justify-content:center;
    }

  }

  #role-gallery{
    background-image:url('images/front/bg/gallery.jpg');
    background-size:cover;
    height:357px;
    position:relative;
    background-position:center top; 
    display:flex;
    justify-content:center;
    align-items:center;

    &:after{
      content:' ';
      position:absolute;
      left:0px;
      top:0px;
      width:100%;
      height:100%;
      background-color:#707070; 
      opacity:0.5;
    }

    a{ 
      font-family:Cinzel; 
      font-size:30px;
      color:#0A1321; 
      position:relative;
      z-index:1;
      background-color:#fff;
      border-radius:20px;
      padding:22px 55px;
    }
  }

  #practical{
    background-color:#132432;

    .container{
      padding:40px 0px 40px 0px;
    }

    h3{
      font-size:24px;
      line-height:30px;
      margin-bottom:20px;
    }
  }

  #newsletter{
    padding:50px 0px;
    background-color: #132432;

    h3{
      font-size:24px;
      line-height:30px;
      margin-bottom:20px;
    }

    #mc_embed_signup{
      margin-top:30px;
    }

    .fieldset{
      display: flex;
      flex-direction: column;

      input[type="email"]{
        background-color:#132432;
        border:1px solid rgba(255,255,255,0.7);
        border-radius:12px;
        outline:none;
        color:rgba(255,255,255,0.7);
        box-sizing:border-box;
        font-size:14px;
        padding:9px 12px;
        width:100%;
        height:50px;
      }

      input[type="submit"]{
        font-family: 'Cinzel Decorative', cursive;
        width:100%;
        text-align:center;
        padding:16px 0px;
        background-color:#fff;
        border-radius:20px;
        color:#0A1321;
        margin-top:20px;
        border:none;
        font-size:16px;
      }
    }

    #mce-responses{
      font-family: Raleway;
      font-size:18px;
      margin-top:20px;
    }
  }

  #dates{


    .container{
      height:100%;
      display:flex;
      flex-direction:column;
      justify-content:center;
      align-items:center;
      flex-wrap:wrap;
      box-sizing:border-box;
      padding:40px 0px;

      h4{
        font-family:'Cinzel Decorative';
        font-size:26px;
      }
    }

    .date-line{
      position:relative;
      display:flex;
      flex-direction:column;
      justify-content:flex-start;
      align-items:center;

      li{
        height:90px;
        position:relative;
        width:160px;
        z-index:1;
        display:flex;
        margin-left:32px;
        flex-direction:row;
        justify-content:flex-start;
        align-items:center;

        h3{
          order:2;
          font-family:'Cinzel';
          color:#8B8B8B;
          font-size:18px;
          margin:0px 0px 0px 20px;
        }

        h2{
          order:1;
          margin-top:8px;
          background-color:#0A1321; 
          border:2px solid #fff; 
          border-radius:20px; 
          width:38px;
          height:38px;
          font-size:22px;
          box-sizing:border-box;
          padding:7px 0px 0px 0px;
          text-align:center;
          display:inline-block;
          font-family:'Cinzel Decorative';
        }

        &:last-child{
          margin-right:0px;
        }
      }

      &:before{
        content: ' '; 
        width:0px;
        height:280px;
        border-left:2px solid #fff;
        position:absolute;
        left:50px;
        top:40px;
      }

    }
  }

  #info{
    background-color:#132432;

    .container{
      padding:40px 0px 60px 0px;
    }

    strong{
      font-weight:bold;
    }

    a{
      border:1px solid #707070; 
      background-color:#fff;
      color:#0A1321;
      font-family:'Cinzel';
      font-size:20px;
      padding:15px 24px;
      border-radius:20px;
      display: block;
      margin:40px auto 0px auto;
      text-align: center;
      width:80%;
     }
  }

  #signup{
    display:flex;
    align-items:center;
    flex-direction:column;
    padding:60px 0px 60px 0px;
    background-image:url("images/mobile/Hand-Spacer.jpg");
    background-size:cover;
    background-position:center center;

    a{
      font-family: 'Cinzel Decorative', cursive;
      width:80%;
      text-align:center;
      padding:22px 0px;
      background-color:#fff;
      border-radius:20px;
      color:#0A1321;
    }

    p{
      width:80%;
      margin:40px auto 0px auto;
      font-family:'Raleway';
      font-size:16px;
      color:#C6C6C6;
      font-style:italic;
    }
  }

  #org-comment{
    display:flex;
    padding:60px 0px;
    flex-direction:row;
    justify-content:center;
    position:relative;
    overflow-x:hidden;

    p{
      width:80%;
      font-family:'Raleway';
      font-size:16px;
      color:#C6C6C6;
      font-style:italic;
      margin-bottom:120px;
    }

  }

}