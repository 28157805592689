#practical-page{
  .practical-hero{
    background-image:asset-url("mobile/Praktiskt-bg.jpg");
  }

  .faq-hero{
    background-image:asset-url("mobile/FAQ-bg.jpg");
  }

  a{
    color:inherit;
    text-decoration: underline;
  }
}

.practical-section{
  margin-top:40px;
  padding-bottom:120px;

  h3{
    font-size:30px;
    margin-bottom:15px;
  }

  p{
    margin-bottom:20px;
    strong{
      font-weight:bold;
    }
  }

  p + h3{
    margin-top:30px;
  }
}