@import 'stylesheets/reset.css';
@import 'stylesheets/burgers.scss';
@import 'stylesheets/not_supported.scss';
@import 'stylesheets/flash.scss';
@import 'stylesheets/frontpage.scss';
@import 'stylesheets/roles.scss';
@import 'stylesheets/phases.scss';
@import 'stylesheets/practical.scss';


a{
  pointer:click;
}

a:visited, 
a:active{
  color:inherit;
}