.role-gallery{
  color:#fff;
  background-image:url("/assets/images/roles/header-bg.svg");
  background-size:200px 222px;
  background-repeat:no-repeat;
  background-position:center top 70px;
  margin:16px auto 320px auto;

  padding:160px 0px;

  .role-gallery-header{

    h1{
      font-family: 'Cinzel Decorative', cursive;
      text-align:center;
      color:#fff;
      font-size:40px;
      margin:0px 0px 120px 0px;
    }
  }

  .role-list{
    display:flex;
    flex-wrap:wrap;
    flex-flow: row wrap;
    justify-content:center;
    margin:40px auto;

    .role-item{
      height:155px;
      width:155px;

      a, label{        
        display:flex;
        flex-direction:column;
        width:100%;
        height:100%;        
        background-color:rgba(19,36,50,0.4);
        justify-content:flex-end;
        position:relative;

        h5{
        font-size:14px;
          background-color:rgba(0,0,0,0.63);
          font-family: 'Cinzel Decorative', cursive;
          color:#fff;          
          width:100%;
          padding:10px 0px 10px 5px;
          box-sizing:border-box;
        }        
      }

      &.interested{
        a:after{
          content: ' ';
          position:absolute;
          left:50%;
          margin-left:-22px;
          background-image:url("/assets/images/roles/HappyFace-White.svg");
          background-size:45px 45px;
          width:45px;
          height:45px;
          top:50%;
          margin-top:-22px;
        }
      }

      &.not-interested{
        a:after{
          content: ' ';
          position:absolute;
          left:50%;
          margin-left:-15px;
          background-image:url("/assets/images/roles/SadFace-White.svg");
          background-size:30px 30px;
          width:30px;
          height:30px;
          top:50%;
          margin-top:-15px;
        }
      }

      &.fra-analytikern, 
      &.antikhandlaren, 
      &.droghandlaren{
        a{
          h5{
            font-size:12px;      
          }
        }
      }
    }
  }
}

.role-page-header{

    z-index:1; 

    .container{
      padding-top:16px;
      display:flex;
      flex-direction:row; 
      justify-content:space-between; 
      align-items:center;
    }

    .close-link{
      height:25px; 
      background-image:url("/assets/images/roles/back-arrow.svg");
      background-size:25px;
      background-repeat:no-repeat;
      display:flex; 
      align-items:center;
      font-size:16px; 
      font-family: 'Cinzel Decorative', cursive;
      border:none;
      padding:0px 0px 0px 35px; 
      color:#fff;

      &.desktop{display:none;}
    }
  

  .how-to-read{
    background-color:rgba(255,255,255,0.75);
    font-family:'Cinzel';
    color:#000;
    text-align:center;
    border:1px solid #707070;
    margin:0px 0px 0px auto;
    display:block;
    font-size:16px;
    padding:10px 18px;
    border-radius:20px;
  }
}

.role-page{
  position: relative;
  top:60px;
  background-color:#132432;

  .role-page-hero{
    position:relative;
    background-repeat:no-repeat;
    background-position:center center;
    background-size:cover;
    height:520px;

    .mask{
      position:absolute;
      left:0px;
      top:0px;
      height:100%;
      width:100%;
      background-color:rgba(19,36,50,0.4);

      &.mobile{
        background-color:rgba(19,36,50,0.8);
      }
    } 

    h1{
      text-align:center;
      font-family: 'Cinzel Decorative', cursive;      
      margin-top:40px;
      font-size:32px;
    }

    .role-alignment-group{
      position:relative;
      display:flex;
      flex-direction:row;
      justify-content:space-between;
      align-items:center;
      flex-wrap:wrap;
      flex-direction:column;
      margin:40px auto 30px auto;
      height:340px;

      img{ display:none; }

      h3{
        color:#fff;
        font-family:'Cinzel';
        font-size:30px;
      }

      &:before{
        width:100%;
        height:100%;
        content: ' '; 
        position:absolute;
        left:0px;
        top:0px;
        background-image:url("/assets/images/roles/logo.svg");
        background-repeat:no-repeat;
        background-size:250px;
        background-position:center center;
        opacity:0.4;
      }
    }

  }

  .role-page-intro{
    background-image:url("/assets/images/roles/spiral_bg.svg");
    background-repeat:repeat-y;
    background-position:center 15px;
    background-size:126px;

    .container{
      padding:40px 0px 60px 0px;

      p{
        font-family:'Raleway';
        font-size:14px;
        width:100%;
        line-height:20px;
      }

      p + p{
        margin-top:20px;
      }
    }
  }

  .role-page-lately{
    background-image:url("/assets/images/roles/latest_bg.jpg");
    background-size:cover;
    background-position:center top;

    .container{
      padding:40px 0px 60px 0px;

      h3{
        font-family:'Cinzel';
        font-size:24px;
        margin-bottom:10px;
      }

      p{
        font-family:'Raleway';
        font-size:14px;
        line-height:20px;
      }

      p + p{
        margin-top:20px;
      }

    }    
  }

  .role-page-balance{
    display:flex;
    flex-direction:row;
    justify-content:stretch;
    flex-wrap:wrap;

    .role-page-balance-item{
      display:flex;
    }

    .role-page-balance-content{
      width:80%;
      margin:0px auto;
      padding:30px 0px 40px 0px;

      h3{
        font-family:'Cinzel';
        font-size:30px;
        line-height:40px;
        margin-bottom:17px;
      }

      p{
        font-family:'Raleway';
        font-size:16px;
        line-height:24px;
      }

      strong{
        font-weight:800;
        font-family:'Raleway';
        text-transform:uppercase;
      }

    }

    .role-page-strength{
      background-color:#132432;
    }

    .role-page-weakness{
      background-color:#131517;
    }
  }

  .role-page-rankings{
    background-color:#0A1321;
    background-image:url("/assets/images/roles/spiral_bg_faded.svg");
    background-repeat:repeat-y;
    background-position:center 15px;
    background-size-width:186px;  

    .container{
      position:relative;
      margin:0px auto;
      width:100%;
      padding:20px 0px;
    }

    .role-page-ranking-item{
      background-color:rgba(17,32,44,0.6);
      box-sizing:border-box; 
      padding:20px;
      margin-bottom:20px;

      &:last-child{
        margin-bottom:0px;
      }

      h3{
        font-family:'Cinzel';
        font-size:24px;
        margin-bottom:10px;
      }

      p{
        font-family:'Raleway';
        font-size:16px;
        line-height:24px;
      }
    }
  }

  .role-page-picker{
    background-color:#0A1321;
    display:flex;
    flex-direction:column;
    align-items:center;
    justify-content:flex-start;
    padding-bottom:300px;

    h2{
      font-family:'Cinzel';
      font-size:18px;
      margin-top:30px;
      margin-bottom:20px;
    }

    .picker-component{
      display:flex;
      flex-direction:row;
      justify-content:center;
      align-items:center;
      margin-bottom:40px;

      a{
        width:120px;
        font-size:30px;
        box-sizing:border-box;
        font-family:'Cinzel';
        color:#fff;
        padding-left:41px;
        background-size:25px 25px;
        background-position:center left;
        background-repeat:no-repeat;        
        opacity:0.5;
        text-decoration:none;

        &.selected-interest{
          opacity:1;
        }

        &.not-interested{
          width:90px;
          background-image:url("/assets/images/roles/SadFace-White.svg");
          margin-right:50px;
        }

        &.interested{
          width:90px;
          background-image:url("/assets/images/roles/HappyFace-White.svg");
        }

      }
    }

    p{
      background-image:url("/assets/images/roles/info.svg");
      background-size:36px 36px;
      background-position:left top; 
      background-repeat:no-repeat;
      font-family:'Raleway';
      width:80%;
      padding-left:52px;
      box-sizing:border-box;
      font-size:14px;
      line-height:20px;
    }
  }
}

.tutorial-active{
    .role-page{
      .role-page-header{
        visibility:hidden;
      }
      .how-to-read{visibility:hidden;}
    }
}


  #role-tutorial{
    min-height: 100%;
  }

  .tutorial-mask{
    position:absolute;
    background-color: rgba(0, 0, 0, 0.8);     
    height:0px;
    width:100%;
    transition: height 0.5s;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .tutorial-close{
    position:absolute;
    color:#fff;
    background-image:url("/assets/images/roles/close.svg");
    font-family:'Cinzel Decorative';
    right:20px;
    background-size:25px 25px;
    line-height:25px;
    font-size:14px;
    padding-left:30px;
    height:25px;
    background-repeat:no-repeat;
  }

  .tutorial-content-container{
    display:flex;
    flex-direction:column;
    align-items:center;
  }

  .tutorial-content{
    display:inline-block;
    top:0px;
    color:#fff;
    background-image:url("/assets/images/roles/info.svg");
    background-position:left top 5px;
    background-repeat:no-repeat;
    background-size:20px 20px;
    padding-left:35px;
    font-family:'Raleway';
    font-size:16px;
    line-height:28px;
    width:70%;
    margin:60px auto 0px auto;

    strong{
      font-weight:bold;
    }

    p + p{
      margin-top:15px;
    }

    p:last-child{
      margin-bottom:40px;
    }
  }

  .tutorial-next{
    background-image:url('/assets/images/front/scroll-arrow.svg');
    width:40px;
    height:40px;
    background-size:40px 40px;
    background-repeat:no-repeat;
    background-size:contain;
    display:inline-block;
  }

  .bottom-mask{
    bottom:0px;
    justify-content: flex-start;

    .tutorial-close{
      top:20px;
    }
  }

  .top-mask{
    top:0px;
    justify-content: flex-end;

    .tutorial-close{
      bottom:20px;
    }

    .tutorial-next{
      margin-bottom:40px;
    }
  }