#phases, 
#practical-page{
  background-color:#132432;
  width:100%;
  min-height:100%;

  .phase-hero, 
  .practical-hero{
    position:relative;
    background-image:url("images/mobile/PhasesCover.jpg");
    background-position: center center;
    background-size:cover;
    height:300px;

    .container{
      min-height:100%;
      display:flex;
      flex-direction:column;
      justify-content:flex-start;
      align-items:center;
      position:relative;
      z-index:1;
    }

    h1{
      display:block;
      margin-bottom:auto;
      font-family:'Cinzel Decorative';
      text-align:center;
      margin-top:140px;
      font-size:35px;
      text-shadow: 0px 0px 2px rgba(0,0,0,0.40);
    }    
  }

  .phase-header, 
  .practical-header{
    width:100%;
    padding-top:32px;    

    .back-link{
      background-image:url("images/roles/back-arrow.svg");
      background-repeat:no-repeat;
      background-position:center left;
      display:inline-block;
      height:20px;
      line-height:20px;
      padding-left:30px;
      background-size:20px 20px;
      font-size:14px;
      font-family:'Cinzel Decorative';
      color: #FFF;
    }
  }

  #intro{
    .container{
      display:flex;
      flex-direction:column;
      justify-content:flex-end;
      align-items:center;
      padding:40px 0px 0px 0px;
    }

    p{
      width:100%;
      font-family:'Raleway';
      color:#FFFFFF;
      font-size:14px;
      line-height:22px;
    }

    .chapter-markers{
      background-image:url("images/phases/PhaseOverview.svg");
      background-size:100%;
      background-position:center top;
      background-repeat:no-repeat;
      width:300px;
      height:440px;
      flex:none;
      margin:30px auto 0px auto;
    }
  }

  .phase-spacer{
    background-position:center center;
    background-repeat:no-repeat;
    background-size:cover;
    position:relative;
  }

  .phase-section{
    .container{
      display:flex;
      flex-direction:column;
      justify-content:center;
      padding:40px 0px;
    }

    h1{
      font-family:'Cinzel Decorative';
      font-size:30px;      
      margin-bottom:20px;
    }

    .description-container{
      width:100%;
    }

    a{
      color:inherit;
      text-decoration: underline;
    }

    p{
      font-size:18px;
      line-height:28px;
      font-family:'Raleway';

      label{
        font-weight:bold;
        display:inline-block;

        &.fixed{
          width:120px;
        }
      }
    }
  }

  #rain{
    background-image:url("images/mobile/phases/PhaseSpacer-0.jpg");
    height:250px;
  }

  #corridor{
    background-image:url("images/mobile/phases/PhaseSpacer-1.jpg");
    height:250px;
  }

  #mountain{
    background-image:url("images/mobile/phases/PhaseSpacer-2.jpg");  
    height:250px;
  }

  #escalator{
    background-image:url("images/mobile/phases/PhaseSpacer-4.jpg"); 
    height:250px;
  }

  #parkinglot{
    background-image:url("images/mobile/phases/PhaseSpacer-3.jpg"); 
    height:250px;
  }

  #drugs{
    background-image:url("images/mobile/phases/PhaseSpacer-5.jpg"); 
    height:250px;
  }

  #split-phase{
    position: relative;
    height:250px;

    &:before{
      content: ' ';
      position: absolute;
      background-image:url("images/mobile/phases/PhaseSpacer-6a.jpg");      
      background-position: center right;
      background-size:cover;
      left:0px;
      top:0px;
      width:50%;
      height:100%;
      z-index:0;
    }

    &:after{
      content: ' ';
      position: absolute;
      background-image:url("images/mobile/phases/PhaseSpacer-6b.jpg");      
      background-position: center left;
      background-size:cover;
      width:50%;
      height:100%;
      top:0px;
      right:0px;
      z-index:0;
    }

  }

}